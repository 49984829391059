.App {
  text-align: center;
}

.image-area {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.inu {
  width: 300px;
}

.rotate-anime {
  -webkit-animation: rotate-anime 3s linear infinite;
          animation: rotate-anime 3s linear infinite;
}

@-webkit-keyframes rotate-anime {
  0%  {-webkit-transform: rotate(0);transform: rotate(0);}
  100%  {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@keyframes rotate-anime {
  0%  {-webkit-transform: rotate(0);transform: rotate(0);}
  100%  {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

.App div.control-area button {
  width: 200px;
  margin: 8px;
}

img.kitaya {
  width: 370px;
}

img.kitaya:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

img.kitaya:active {
  box-shadow: none;
}

.furueru-anime {
  display: inline-block;
  -webkit-animation: furueru-anime .1s  infinite;
          animation: furueru-anime .1s  infinite;
}

img.kitaya.furueru-anime:hover {
  box-shadow: none;
}

@-webkit-keyframes furueru-anime {
  0% {-webkit-transform: translate(0px, 0px) rotateZ(0deg);transform: translate(0px, 0px) rotateZ(0deg)}
  25% {-webkit-transform: translate(6px, 6px) rotateZ(3deg);transform: translate(6px, 6px) rotateZ(3deg)}
  50% {-webkit-transform: translate(0px, 6px) rotateZ(0deg);transform: translate(0px, 6px) rotateZ(0deg)}
  75% {-webkit-transform: translate(6px, 0px) rotateZ(-3deg);transform: translate(6px, 0px) rotateZ(-3deg)}
  100% {-webkit-transform: translate(0px, 0px) rotateZ(0deg);transform: translate(0px, 0px) rotateZ(0deg)}
}

@keyframes furueru-anime {
  0% {-webkit-transform: translate(0px, 0px) rotateZ(0deg);transform: translate(0px, 0px) rotateZ(0deg)}
  25% {-webkit-transform: translate(6px, 6px) rotateZ(3deg);transform: translate(6px, 6px) rotateZ(3deg)}
  50% {-webkit-transform: translate(0px, 6px) rotateZ(0deg);transform: translate(0px, 6px) rotateZ(0deg)}
  75% {-webkit-transform: translate(6px, 0px) rotateZ(-3deg);transform: translate(6px, 0px) rotateZ(-3deg)}
  100% {-webkit-transform: translate(0px, 0px) rotateZ(0deg);transform: translate(0px, 0px) rotateZ(0deg)}
}

.image-area {
  display: flex;
}

.before-wanko, .after-wanko, .new-wanko {
  width: 300px;
}

.slider-area {
  width: 300px;
}

.before-wanko {
  position: absolute;
  top: 0;
  left: 0;
}

.after-wanko, .new-wanko {
  position: absolute;
  top: 0;
  left: 0;
}

